import React from "react"
import Devices from "../assets/images/devices.png"
import Check from "../assets/images/nike.png"
import iQuestLogo from "../assets/images/iQuestLogo.png"

export default function Section3() {
  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const bubblesThree = document.querySelector("#bubblesThree")
      bubblesThree.style.backgroundPositionY = offset * -0.3 + 600 + "px"
    })

  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const bubblesThreeB = document.querySelector("#bubblesThreeB")
      bubblesThreeB.style.backgroundPositionY = offset * -0.6 + 1300 + "px"
    })

  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const bubblesFour = document.querySelector("#bubblesFour")
      bubblesFour.style.backgroundPositionY = offset * -0.3 + 1200 + "px"
    })

  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const bubblesFourB = document.querySelector("#bubblesFourB")
      bubblesFourB.style.backgroundPositionY = offset * -0.6 + 2000 + "px"
    })
  return (
    <div className="section3">
      <div id="bubblesThree" className="section3-background-first">
        <div id="bubblesThreeB" className="section3-background-first-big">
          <div className="flex align-center justify-center m-b-4 ">
            <div className="flex-1 flex direction-column service-text justify-center">
              <p className="h2 service-title">Další služby, které nabízíme:</p>
              <div className="flex align-center">
                <img alt="check" className="service-icon m-r-2" src={Check} />
                <p>detailní analýza záměru</p>
              </div>
              <div className="flex align-center">
                <img alt="check" className="service-icon m-r-2" src={Check} />
                <p>průběžná konzultace a řízení</p>
              </div>
              <div className="flex align-center">
                <img alt="check" className="service-icon m-r-2" src={Check} />
                <p>návrh aplikace</p>
              </div>
              <div className="flex align-center">
                <img alt="check" className="service-icon m-r-2" src={Check} />
                <p>vývoj aplikace</p>
              </div>
              <div className="flex align-center">
                <img alt="check" className="service-icon m-r-2" src={Check} />
                <p>systémová integrace</p>
              </div>
              <div className="flex align-center">
                <img alt="check" className="service-icon m-r-2" src={Check} />
                <p>testování</p>
              </div>
              <div className="flex align-center">
                <img alt="check" className="service-icon m-r-2" src={Check} />
                <p>pomoc při jednání s dodavateli</p>
              </div>
            </div>
            <img alt="devices" src={Devices} className="flex-1 service-img" />
          </div>
          <a
            href="#start"
            className="bubble bubble-btn-global bubble-btn-global-big bubble-btn m-auto text-center"
          >
            Rezervovat termín konzultace
          </a>
        </div>
      </div>
      <div id="bubblesFour" className="section3-background-second">
        <div id="bubblesFourB" className="section3-background-second-big">
          <div className="about ">
            <p className="h2 italic m-b-4">Proč iQuest?</p>
            <p>Propojujeme znalosti z různých oborů...</p>
            <p>
              Za více než 17 let se nám podařilo vyvinout systémy pro zákazníky
              z různých obchodních a společenských oborů (merchandising,
              farmaceutický průmysl, automotive, distribuční společnosti,
              zdravotnictví, telekomunikace, marketing, sociální sítě,
              informační servis, doprava, pojišťovnictví, stavebnictví,
              informační technologie (Certicon, Summit)).
            </p>
            <p>
              Prokázali jsme, že máme schopnost přijímat nové poznatky, pronikat
              do obchodních strategií a navrhovat jejich podporu pomocí na míru
              vyvinutých informačních systémů.
            </p>
            <p>
              Díky těmto zkušenostem kreativně navrhujeme a tvoříme řešení pro
              další klienty a přinášíme nápady stávajícím zákazníkům.
            </p>
          </div>
          <div className="iquest-logo">
            <a href="https://www.iquest.cz/" target="_blank">
              <img
                alt="iQuestLogo"
                className="iquest-logo-img pointer"
                src={iQuestLogo}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
