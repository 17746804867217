import Footer from "../components/Footer"
import Layout from "../components/layout"
import Logo from "../assets/images/logo.png"
import PartnersSlider from "../components/PartnersSlider"
import PhoneIcon from "../assets/images/mobile.svg"
import React from "react"
import Section2 from "../components/Section2"
import Section3 from "../components/Section3"
import ShowConsultations from "../components/ShowConsultations"

export default function Home() {
  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const sectionOne = document.querySelector("#sectionOne")
      sectionOne.style.backgroundPositionY = offset * -0.6 + "px"
    })

  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const sectionTwo = document.querySelector("#sectionTwo")
      sectionTwo.style.backgroundPositionY = offset * -0.3 + 650 + "px"
    })

  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const sectionTwoB = document.querySelector("#sectionTwoB")
      sectionTwoB.style.backgroundPositionY = offset * -0.6 + 850 + "px"
    })

  return (
    <Layout>
      <div id="start" className="flex justify-space-between m-t-2">
        <span className="iquest-logo-animation">
          <a href="https://www.iquest.cz/" target="_blank">
            <img alt="logo" className="iquest-logo-small" src={Logo} />
          </a>
        </span>

        <div className="bubble">
          <img alt="phone-icon" className="bubble-icon" src={PhoneIcon} />
          <a className="phone-link" href="tel:+420777314768">
            +420 777 314 768
          </a>
        </div>
      </div>

      <div id="sectionOne" className="section-1 m-t-4">
        <div className="section-1-title">
          <div className="section-1-width">
            <h1>Online konzultace vývoje software ZDARMA</h1>
            <p>
              Poraďte se nezávazně o vašem novém projektu webové nebo mobilní
              aplikace.
              <br />
              <br />
              Promluvte si s odborníky na vývoj a získejte odpovědi na vaše
              dotazy ohledně:
            </p>
            <div className="flex wrap respond-wrap">
              <div className="bubble bubble-auto m-b-1">cíle projektu</div>
              <div className="bubble bubble-auto m-b-1">ceny vývoje</div>
              <div className="bubble bubble-auto m-b-1">funkcí aplikace</div>
              <div className="bubble bubble-auto m-b-1">vhodné technologie</div>
              <div className="bubble bubble-auto m-b-1">metodologie vývoje</div>
            </div>
          </div>
        </div>
        <div className="consultations-tab">
          <ShowConsultations />
        </div>
      </div>

      <div id="sectionTwo" className="section-2 ">
        <div id="sectionTwoB" className="section-2-background-big">
          <Section2 />
        </div>
      </div>
      <div className="partners">
        <PartnersSlider />
      </div>
      <Section3 />
      <Footer />
    </Layout>
  )
}
