import "../styles/swiperNavigation/swiperNavigation.scss"
import "swiper/swiper.scss"

import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"

import CiaNews from "../assets/images/cia-news.png"
import GS from "../assets/images/greenswan.png"
import LogoCemio from "../assets/images/cemio.png"
import debounce from "../utils/debounce"
import Ignum from "../assets/images/ignum.png"
import Magistra from "../assets/images/magistra.png"
import Hipp from "../assets/images/hipp1.svg"
import Assa from "../assets/images/assaabloy.png"
import Euro from "../assets/images/eurosignal.png"

SwiperCore.use([Navigation])

const calculateSlidesCount = () => {
  const partnerBlockWidth = document.querySelector(".partners")?.clientWidth
  if (!partnerBlockWidth) return 3

  const slidesCount = Math.floor(partnerBlockWidth / 250) // TODO nastavit magic 250 na neco :)
  return slidesCount
}

export default function PartnersSlider() {
  const [slidesPerView, setSlidesPerView] = useState(3)

  const debouncedResize = debounce(() => {
    setSlidesPerView(calculateSlidesCount())
  }, 250)

  useEffect(() => {
    setSlidesPerView(calculateSlidesCount())
    window.addEventListener("resize", debouncedResize)
    return function cleanup() {
      window.removeEventListener("resize", debouncedResize)
    }
  }, [debouncedResize])

  return (
    <>
      <p className="h2 italic text-center title2-width m-auto my-swiper-title">
        Reference
      </p>
      <Swiper
        className="my-swiper"
        navigation
        spaceBetween={0}
        slidesPerView={slidesPerView}
      >
        <SwiperSlide className="items">
          <img alt="logo-Ignum" className="items-img" src={Ignum} />
          <p className="items-big">
            Informační systém a zákaznický portál pro IGNUM
          </p>
          <p className="items-small">
            Vnitřní informační systém a zákaznický portál pro jednoho z
            největších poskytovatelů webhostingu a serverhostingu v České
            republice.
          </p>
        </SwiperSlide>
        <SwiperSlide className="items">
          <img alt="logo-Hipp" className="items-img" src={Hipp} />
          <p className="items-big">HiPP Czech s.r.o. - dlouhodobá spolupráce</p>
          <p className="items-small">
            Rozsáhlá webová aplikace pro management obchodních reprezentantů.
          </p>
        </SwiperSlide>
        <SwiperSlide className="items">
          <img alt="logo-Magistra" className="items-img" src={Magistra} />
          <p className="items-big">
            Mobilní aplikace pro řetězec lékáren Magistra.
          </p>
          <p className="items-small">
            Pro řetězec lékáren Magistra jsme vytvořili mobilní aplikaci, pomocí
            které si uživatelé mohou najít svou oblíbenou lékárnu a mohou využít
            speciálních nabídek v rámci věrnostního programu.
          </p>
        </SwiperSlide>
        <SwiperSlide className="items">
          <img alt="logo-Assa" className="items-img " src={Assa} />
          <p className="items-big">
            ASSA ABLOY Entrance Systems, spol. s.r.o. - dlouhodobá spolupráce
          </p>
          <p className="items-small">
            Interní informační systém - webová aplikace - pro správu a
            organizaci zakázek.
          </p>
        </SwiperSlide>
        <SwiperSlide className="items">
          <img alt="logo-Euro" className="items-img items-img-big" src={Euro} />
          <p className="items-big">
            Vnitrofiremní informační systém pro společnost Eurosignal
          </p>
          <p className="items-small">
            Komplexní vnitrofiremní informační systém s klientskou samoobsluhou
            pro poskytovatele internetového připojení.
          </p>
        </SwiperSlide>
        <SwiperSlide className="items">
          <img alt="logo-GS" className="items-img" src={GS} />
          <p className="items-big">
            CRM (client relationship management) systém pro Green-Swan
            Pharmaceuticals CR a.s.
          </p>
          <p className="items-small">
            CRM systém na míru management obchodních reprezentantů. Zrychlili
            jsme práci týmu obchodních reprezentantů.
          </p>
        </SwiperSlide>
        <SwiperSlide className="items">
          <img alt="logo-cia" className="items-img" src={CiaNews} />
          <p className="items-big">
            Datový sklad a klientská webová aplikace pro informační agenturu ČIA
            News
          </p>
          <p className="items-small">
            Vytvořili jsme rozsáhlý datový sklad a klientskou webovou aplikaci
            pro jednu z největších informačních agentur v České republice.
          </p>
        </SwiperSlide>
        <SwiperSlide className="items">
          <img alt="logo-cemio" className="items-img" src={LogoCemio} />
          <p className="items-big">
            CRM (client relationship management) systém na míru pro Cemio
            Switzerland AG
          </p>
          <p className="items-small">
            CRM systém na míru management obchodních reprezentantů
          </p>
        </SwiperSlide>
      </Swiper>
      <div className="text-center width-100 m-b-8">
        Více referencí{" "}
        <a
          className="pointer swiper-link"
          href="https://www.iquest.cz/reference"
          target="_blanc"
        >
          zde
        </a>
      </div>
    </>
  )
}
