import React from "react"
import MobileBig from "../assets/images/mobile-big.png"
import WebApp from "../assets/images/tablet.png"
import CompanySystem from "../assets/images/companySystem.png"
import System from "../assets/images/system.png"

export default function Section2() {
  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const bubblesOne = document.querySelector("#bubblesOne")
      bubblesOne.style.backgroundPositionY = offset * -0.3 + 550 + "px"
    })

  if (typeof window !== `undefined`)
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset
      const bubblesTwo = document.querySelector("#bubblesTwo")
      bubblesTwo.style.backgroundPositionY = offset * -0.6 + 1050 + "px"
    })

  return (
    <div>
      <p className="h2 text-center title2-width m-auto">
        Jsme odborníci s dlouholetými zkušenostmi vývoje velkých softwarových
        řešení.
      </p>
      <div className="img-section">
        <div className="img-section-box">
          <img alt="big-mobile" className="img-section-img" src={MobileBig} />
          <p className="img-section-text">
            <strong>Mobilních aplikací</strong>
          </p>
        </div>
        <div className="img-section-box">
          <img alt="web-app" className="img-section-img" src={WebApp} />
          <p className="img-section-text">
            <strong>Webových aplikací</strong>
          </p>
        </div>
        <div className="img-section-box  ">
          <img
            alt="company-info-systems"
            className="img-section-img"
            src={CompanySystem}
          />
          <p className="img-section-text">
            <strong>Firemních informačních systémů</strong>
          </p>
        </div>
        <div className="img-section-box">
          <img
            alt="complicated-sites"
            className="img-section-img img-section-img-last"
            src={System}
          />
          <p className="img-section-text">
            <strong>Rozsáhlých webů</strong>
          </p>
        </div>
      </div>

      <a
        href="#start"
        className="bubble bubble-btn-global bubble-btn-global-big bubble-btn m-auto text-center m-t-3"
      >
        Rezervovat termín konzultace
      </a>
      <div id="bubblesOne" className=" section-2-second-background">
        <div id="bubblesTwo" className=" section-2-second-background-big">
          <div className="m-t-big "></div>
          <p className="h2 text-center title2-width m-auto m-b-4">
            Jak probíhá konzultace a co získáte?
          </p>
          <div className="flex width-90 m-auto p-b-4 consultation-points-direction">
            <div className="consultation-points">
              <div className="flex m-b-2 align-center">
                <div className="bubble bubble-circle">01</div>
                <div>
                  <p className="m-l-2">
                    Rezervujete si termín v našem rezervačním systému a obdržíte
                    e-mail s pozvánkou.{" "}
                    <a
                      href="#start"
                      className="pointer consultation-points-link"
                    >
                      Rezervovat.
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex m-b-2 align-center">
                <div className="bubble bubble-circle">02</div>
                <p className="m-l-2">
                  Konzultace probíhá přes videohovor. Všichni konzultanti jsou
                  vůči vám zavázaní mlčenlivostí, jejíž platnost začíná
                  zahájením hovoru.
                </p>
              </div>
              <div className="flex m-b-2 align-center">
                <div className="bubble bubble-circle">03</div>
                <p className="m-l-2">
                  Představíte nám svůj záměr, ke kterému vám dáme zpětnou vazbu.
                </p>
              </div>
            </div>
            <div className=" consultation-points">
              <div className="flex m-b-2 align-center">
                <div className="bubble bubble-circle">04</div>
                <p className="m-l-2">
                  Nastíníme vám odhad ceny a vysvětlíme, z čeho se cena skládá a
                  co ji ovlivňuje.
                </p>
              </div>
              <div className="flex m-b-2 align-center">
                <div className="bubble bubble-circle">05</div>
                <p className="m-l-2">
                  Vysvětlíme vám, jak funguje celý proces vývoje a poradíme, 
                  jak zvolit vhodnou technologii a metodu vývoje.
                </p>
              </div>
              <div className="flex m-b-2 align-center">
                <div className="bubble bubble-circle">06</div>
                <p className="m-l-2">
                  Poradíme vám, jak vybrat toho správného dodavatele.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
