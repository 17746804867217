import React from "react"
import Phone from "../assets/images/phone.svg"
import Email from "../assets/images/email.svg"
import FB from "../assets/images/facebook.svg"
import Twitter from "../assets/images/twitter.svg"
import In from "../assets/images/linkedin.svg"

export default function Footer() {
  return (
    <div className="footer m-b-1">
      <div className="flex footer-direction-small">
        <div className="flex footer-about">
          <div className="flex-1 footer-box">
            <p>
              <a
                href="https://goo.gl/maps/dYaZdK3nT3EDw8oS9"
                className="footer-link"
                target="_blank"
              >
                <strong>Sídlo a centrála Praha</strong>
              </a>
            </p>
            <p>iQuest s.r.o.</p>
            <p>Sněmovní 9</p>
            <p>118 00 Praha 1 - Malá strana</p>
          </div>
          <div className="flex-1 footer-box">
            <p>
              <a
                href="https://goo.gl/maps/HJok83spooY5rhvC6"
                className="footer-link"
                target="_blanc"
              >
                <strong>Kancelář Brno</strong>
              </a>
            </p>
            <p>iQuest s.r.o.</p>
            <p>Cejl 494/25</p>
            <p>602 00 Brno</p>
          </div>
        </div>
        <div className="flex footer-links">
          <div className="flex-1 footer-box m-t-2">
            <div className="flex m-b-1">
              <img alt="phone" className="footer-icon" src={Phone} />
              <a className="phone-link m-none" href="tel:+420777314768">
                777 314 768
              </a>
            </div>
            <div className="flex">
              <img alt="email" className="footer-icon" src={Email} />

              <a href="mailto:vyvojari@iquest.cz" className="email-link m-none">
                vyvojari@iquest.cz
              </a>
            </div>
          </div>
          <div className="flex-1 footer-box m-t-2">
            <a href="https://www.facebook.com/iquest.cz" target="_blank">
              <img alt="Facebook" className="m-r-4 footer-icon-link" src={FB} />
            </a>
            <a href="https://twitter.com/iQuestCZ" target="_blank">
              <img
                alt="Twitter"
                className="footer-icon-link m-r-4"
                src={Twitter}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/iquest-s-r-o-/"
              target="_blank"
            >
              <img alt="Linked" className="footer-icon-link" src={In} />
            </a>
          </div>
        </div>
      </div>
      <div className=" color-white footer-line">
        &copy; iQuest. All Rights Reserved.
      </div>
    </div>
  )
}
